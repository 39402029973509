import API from '@/services/API.js'
import router from '@/router'

export const namespaced = true

export const state = {
  paymentIntent: {
    isCreating: false,
    clientSecret: null,
    stripeAccount: null,
    error: null,
    futureUsageDescription: null
  }
}

export const mutations = {
  SET_PAYMENT_INTENT_IS_CREATING (state, val) {
    state.paymentIntent.isCreating = val
  },
  SET_PAYMENT_INTENT_CLIENT_SECRET (state, val) {
    state.paymentIntent.clientSecret = val
  },
  SET_PAYMENT_INTENT_STRIPE_ACCOUNT (state, val) {
    state.paymentIntent.stripeAccount = val
  },
  SET_PAYMENT_INTENT_ERROR (state, val) {
    state.paymentIntent.error = val
  },
  SET_PAYMENT_INTENT_FUTURE_USAGE_DESCRIPTION (state, val) {
    state.paymentIntent.futureUsageDescription = val
  }
}

export const actions = {
  createPaymentIntent ({ commit, dispatch }, { session, amount }) {
    commit('SET_PAYMENT_INTENT_IS_CREATING', true)
    commit('SET_PAYMENT_INTENT_CLIENT_SECRET', null)
    commit('SET_PAYMENT_INTENT_STRIPE_ACCOUNT', null)
    commit('SET_PAYMENT_INTENT_ERROR', null)
    commit('SET_PAYMENT_INTENT_FUTURE_USAGE_DESCRIPTION', null)
    return API.httpClient.post('booking/create-payment-intent', {
      session,
      amount
    })
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_PAYMENT_INTENT_CLIENT_SECRET', data.client_secret)
          commit('SET_PAYMENT_INTENT_STRIPE_ACCOUNT', data.stripe_account)
          commit('SET_PAYMENT_INTENT_FUTURE_USAGE_DESCRIPTION', data.future_usage_description)
        } else if (data.status === 'already_confirmed') {
          router.push({ name: 'CheckinConfirmationPage' })
        } else if (data.status === 'hold_not_found') {
          dispatch('checkin/resetCheckin', undefined, { root: true })
        } else {
          commit('SET_PAYMENT_INTENT_ERROR', window.i18n.t('generic.something_went_wrong'))
        }
      })
      .catch((error) => {
        console.log('createPaymentIntent error:', error)
        commit('SET_PAYMENT_INTENT_ERROR', window.i18n.t('generic.something_went_wrong'))
      })
      .finally(() => {
        commit('SET_PAYMENT_INTENT_IS_CREATING', false)
      })
  }
}

export const getters = {}
