import axios from 'axios'

// Funktion för att hämta baseURL baserat på namnrymden och miljön
function getBaseURL () {
  // Kommentera bort nedan för att testa lokal build mot prod och med rätt namespace
  if (process.env.NODE_ENV !== 'production') {
    return 'https://web.test/api/'
  }

  const hostname = window.location.hostname
  const domainMap = {
    'boka.bastadcamping.se': 'https://webbastad.campingonline.se/api',
    'boka.bodahamnscamping.se': 'https://webbodahamn.campingonline.se/api',
    'bokabodahamn.campingonline.se': 'https://webbodahamn.campingonline.se/api'
  }
  if (domainMap[hostname]) {
    return domainMap[hostname]
  }
  const namespace = window.location.pathname.split('/')[1]
  return `https://web${namespace}.campingonline.se/api/`
}

const baseURL = getBaseURL()

const httpClient = axios.create({
  baseURL,
  headers: {
    accept: 'application/json',
    'ngrok-skip-browser-warning': 1
  }
})

const setToken = function (token) {
  if (token) {
    httpClient.defaults.headers.Authorization = 'Bearer ' + token
  } else {
    httpClient.defaults.headers.Authorization = ''
  }
}

const setLanguage = function (language) {
  httpClient.defaults.headers['x-user-language'] = language
}

console.log('setting axios interceptors')
// Lägg till en interceptor för att hantera NXDOMAIN-fel och andra nätverksfel
httpClient.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', error)
    if (error.isAxiosError && !error.response) {
      // Nätverksfel eller CORS-problem, namespace finns förmodligen inte så skickar till boka sidan
      console.error('Network Error:', error.message)
      window.location.href = 'https://www.campingonline.se/boka'
    }
    return Promise.reject(error)
  }
)

export default { setToken, httpClient, setLanguage }
