export const namespaced = true

export const state = {
  checkInDate: {
    date: null,
    readable: '',
    param: ''
  },
  checkOutDate: {
    date: null,
    readable: '',
    param: ''
  },
  dateValidation: {
    hasError: false,
    errorText: ''
  },
  adults: 2,
  children: 0,
  dogs: 0,
  filter: {
    activeFilterId: null,
    param: null
  }
}

export const mutations = {
  SET_CHECK_IN_DATE (state, checkInDate) {
    state.checkInDate = checkInDate
    this.dispatch('search/validateDates')
    this.dispatch('availability/fetchAvailability')
    this.dispatch('availability/fetchPrices')
  },
  SET_CHECK_OUT_DATE (state, checkOutDate) {
    state.checkOutDate = checkOutDate
    this.dispatch('search/validateDates')
    this.dispatch('availability/fetchAvailability')
    this.dispatch('availability/fetchPrices')
  },
  SET_DATES (state, { checkInDate, checkOutDate }) {
    state.checkInDate = checkInDate
    state.checkOutDate = checkOutDate
    this.dispatch('search/validateDates')
    this.dispatch('availability/fetchAvailability')
    this.dispatch('availability/fetchPrices')
  },
  SET_DATE_VALIDATION (state, val) {
    state.dateValidation = val
  },
  SET_ADULTS (state, adults) {
    state.adults = adults
  },
  SET_CHILDREN (state, children) {
    state.children = children
  },
  SET_DOGS (state, val) {
    state.dogs = val
  },
  SET_FILTER (state, type) {
    if (type) {
      state.filter.param = type.text.toLowerCase()
      state.filter.activeFilterId = type.id
    } else {
      state.filter.param = null
      state.filter.activeFilterId = null
    }
  }
}

export const actions = {
  navigatingToSearchPage ({ state, rootState, commit }, route) {
    // Körs innan initialise vid sidladdning
    let dateIn = null; let dateOut = null; let adults = null; let children = null; let dogs = null
    if (route.to.params) {
      dateIn = window.dayjs(route.to.params.checkindate)
      dateOut = window.dayjs(route.to.params.checkoutdate)
      adults = parseInt(route.to.params.adults, 10) // returnerar int eller NaN
      children = parseInt(route.to.params.children, 10) // returnerar int eller NaN
      dogs = parseInt(route.to.params.dogs, 10) // returnerar int eller NaN
    } else {
      return false
    }
    // Adults, children och dogs är int eller NaN
    if (!dateIn || !dateIn.isValid() || !dateOut || !dateOut.isValid() || isNaN(adults) || isNaN(children) || isNaN(dogs)) {
      // Använd bara routevärden om alla parametrar är korrekt angivna
      return false
    }

    const checkInDate = {
      date: dateIn.clone(),
      readable: dateIn.format('ddd D MMM'),
      param: dateIn.format('YYYY-MM-DD')
    }

    const checkOutDate = {
      date: dateOut.clone(),
      readable: dateOut.format('ddd D MMM'),
      param: dateOut.format('YYYY-MM-DD')
    }

    commit('SET_DATES', { checkInDate, checkOutDate })

    state.adults = adults
    state.children = children
    state.dogs = dogs

    if (route.to.params.filter) {
      if (rootState.camping && rootState.camping.lodging_types) {
        const type = rootState.camping.lodging_types.find(e => e.text.toLowerCase() == route.to.params.filter.toLowerCase())
        commit('SET_FILTER', type)
      }
    }
  },
  navigatedToSearchPage () {
    this.dispatch('availability/fetchAvailability')
  },
  validateDates ({ state, commit }) {
    if (!state.checkInDate.date || !state.checkInDate.date.isValid()) {
      commit('SET_DATE_VALIDATION', {
        hasError: true,
        errorText: window.i18n.t('validation.checkin_required')
      })
    } else if (!state.checkOutDate.date || !state.checkOutDate.date.isValid()) {
      commit('SET_DATE_VALIDATION', {
        hasError: true,
        errorText: window.i18n.t('validation.checkout_required')
      })
    } else if (state.checkInDate.date.isBefore(window.dayjs().startOf('day'))) {
      commit('SET_DATE_VALIDATION', {
        hasError: true,
        errorText: window.i18n.t('validation.new_checkin_required')
      })
    } else if (state.checkOutDate.date.isSameOrBefore(state.checkInDate.date)) {
      commit('SET_DATE_VALIDATION', {
        hasError: true,
        errorText: window.i18n.t('validation.later_checkout_required')
      })
    } else {
      commit('SET_DATE_VALIDATION', {
        hasError: false,
        errorText: ''
      })
    }
  }
}
