import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/SearchPage.vue')
  },
  {
    path: '/boende/:category',
    name: 'CategoryPage',
    component: () => import('../views/CategoryPage.vue')
  },
  {
    path: '/logga-in',
    name: 'LoginPage',
    component: () => import('../views/LoginPage.vue')
  },
  {
    path: '/kontaktuppgifter',
    name: 'ReviewPage',
    component: () => import('../views/ReviewPage.vue')
  },
  {
    path: '/tillval',
    name: 'AddonsPage',
    component: () => import('../views/AddonsPage.vue')
  },
  {
    path: '/betalning',
    name: 'PaymentPage',
    component: () => import('../views/PaymentPage.vue')
  },
  {
    path: '/mina-bokningar',
    name: 'BookingsPage',
    component: () => import('../views/BookingsPage.vue')
  },
  {
    path: '/bekr%C3%A4ftelse',
    name: 'ConfirmationPage',
    component: () => import('../views/ConfirmationPage.vue')
  },
  {
    path: '/behandlar', // Skickar alltid till bekräftelse som kontrollerar med server, kan inte lita på stripes status
    beforeEnter: (to, from, next) => {
      next('/bekr%C3%A4ftelse')
    }
  },

  /* Självincheckningsroutes */
  {
    path: '/checkin',
    component: () => import('@/views/checkin/ParentPage.vue'),
    meta: { isSelfCheckin: true },
    children: [
      {
        path: '/',
        name: 'CheckinIndexPage',
        component: () => import('@/views/checkin/IndexPage.vue'),
        meta: { isSelfCheckin: true }
      },
      {
        path: 'nights',
        name: 'CheckinSelectNights',
        component: () => import('@/views/checkin/SelectNightsPage.vue'),
        meta: { isSelfCheckin: true }
      },
      {
        path: 'map',
        name: 'CheckinMap',
        component: () => import('@/views/checkin/MapPage.vue'),
        meta: { isSelfCheckin: true }
      },
      {
        path: 'guest-details',
        name: 'CheckinGuestDetails',
        component: () => import('@/views/checkin/GuestDetailsPage.vue'),
        meta: { isSelfCheckin: true }
      },
      {
        path: 'payment',
        name: 'CheckinPaymentPage',
        component: () => import('@/views/checkin/PaymentPage.vue'),
        meta: { isSelfCheckin: true }
      },
      {
        path: 'confirmation',
        name: 'CheckinConfirmationPage',
        component: () => import('@/views/checkin/ConfirmationPage.vue'),
        meta: { isSelfCheckin: true }
      },
      {
        path: 'behandlar', // Skickar alltid till bekräftelse som kontrollerar med server, kan inte lita på stripes status
        meta: { isSelfCheckin: true },
        beforeEnter: (to, from, next) => {
          next('/checkin/confirmation')
        }
      }
    ]
  },

  /* Sökroute, måste vara sist eftersom bara optional parametrar */
  {
    path: '/:checkindate?/:checkoutdate?/:adults?/:children?/:dogs?/:filter?',
    name: 'SearchPage',
    component: () => import('../views/SearchPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: (() => {
    const hostname = window.location.hostname
    if (hostname === 'boka.campingonline.se' || hostname === 'booking.test' || hostname === 'bokabeta.campingonline.se') {
      const namespace = window.location.pathname.split('/')[1]
      return namespace ? `/${namespace}` : '/'
    }
    return '/'
  })(),
  routes
})

router.beforeEach((to, from, next) => {
  /* console.log('navigating', 'to', to, 'from', from) */
  store.dispatch('navigating', { from, to })
  next()
})
router.afterEach((to, from) => {
  /* console.log('navigated', 'to', to, 'from', from) */
  store.dispatch('navigated', { from, to })
})

export default router
