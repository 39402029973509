import API from '@/services/API.js'
import router from '@/router'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth'

export const namespaced = true
let firebase

export const state = {
  apiToken: null,
  isConnecting: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isSavingUser: false,
  showLoggedInSnackback: false,
  showLoggedOutSnackback: false,
  user: null,
  editedUser: null,
  loginAuth: {
    email: '',
    password: '',
    errorCode: ''
  },
  passwordReset: {
    isSendingEmail: false,
    emailSentAt: null,
    email: null
  },
  otpLogin: {
    email: '',
    sentAt: null,
    isSendingEmail: false,
    emailFailed: false,
    loginErrorCode: ''
  }
}

export const mutations = {
  SET_API_TOKEN (state, token) {
    state.apiToken = token
    API.setToken(token)
    if (token) {
      localStorage.setItem('api_token', token)
    } else {
      localStorage.removeItem('api_token')
    }
  },
  SET_IS_CONNECTING (state, val) {
    state.isConnecting = val
  },
  SET_IS_LOGGING_IN (state, val) {
    state.isLoggingIn = val
  },
  SET_IS_LOGGING_OUT (state, val) {
    state.isLoggingOut = val
  },
  SET_IS_SAVING_USER (state, val) {
    state.isSavingUser = val
  },
  SET_LOGIN_AUTH (state, val) {
    state.loginAuth = val
  },
  SET_SHOW_LOGGED_IN_SNACKBAR (state, val) {
    state.showLoggedInSnackback = val
  },
  SET_SHOW_LOGGED_OUT_SNACKBAR (state, val) {
    state.showLoggedOutSnackback = val
  },
  SET_USER (state, user) {
    state.user = user
    state.editedUser = Object.assign({}, user)
  },

  // edited user
  SET_EDITED_USER (state, user) {
    state.editedUser = user
  },
  SET_EDITED_USER_GIVEN_NAME (state, val) {
    this._vm.$set(state.editedUser, 'given_name', val)
  },
  SET_EDITED_USER_SURNAME (state, val) {
    this._vm.$set(state.editedUser, 'surname', val)
  },
  SET_EDITED_USER_COUNTRY (state, val) {
    this._vm.$set(state.editedUser, 'country', val)
  },
  SET_EDITED_USER_CITY (state, val) {
    this._vm.$set(state.editedUser, 'city', val)
  },
  SET_EDITED_USER_ZIP (state, val) {
    this._vm.$set(state.editedUser, 'zip', val)
  },
  SET_EDITED_USER_STREET_NUMBER (state, val) {
    this._vm.$set(state.editedUser, 'street_number', val)
  },
  SET_EDITED_USER_STREET_NAME (state, val) {
    this._vm.$set(state.editedUser, 'street_name', val)
  },
  SET_EDITED_USER_PHONE_NUMBER (state, val) {
    this._vm.$set(state.editedUser, 'phone_number', val)
  },
  SET_EDITED_USER_DIALLING_CODE_COUNTRY (state, val) {
    this._vm.$set(state.editedUser, 'dialling_code_country', val)
  },

  // Password reset
  SET_PASSWORD_RESET_IS_SENDING_EMAIL (state, val) {
    state.passwordReset.isSendingEmail = val
  },
  SET_PASSWORD_RESET_EMAIL_SENT_AT (state, val) {
    state.passwordReset.emailSentAt = val
  },
  SET_PASSWORD_RESET_EMAIL (state, val) {
    state.passwordReset.email = val
  },

  // OTP login
  SET_OTP_LOGIN_EMAIL (state, val) {
    state.otpLogin.email = val
  },
  SET_OTP_LOGIN_SENT_AT (state, val) {
    state.otpLogin.sentAt = val
  },
  SET_OTP_LOGIN_IS_SENDING_EMAIL (state, val) {
    state.otpLogin.isSendingEmail = val
  },
  SET_OTP_LOGIN_EMAIL_FAILED (state, val) {
    state.otpLogin.emailFailed = val
  },
  SET_OTP_LOGIN_ERROR_CODE (state, val) {
    state.otpLogin.loginErrorCode = val
  }
}

export const actions = {
  initialise ({ commit, dispatch, state }, localStateApiToken) {
    if (!firebase) {
      const firebaseConfig = {
        apiKey: process.env.VUE_APP_API_KEY,
        authDomain: process.env.VUE_APP_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_DATABSE_URL,
        projectId: process.env.VUE_APP_PROJECT_ID,
        storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_APP_ID
      }
      firebase = initializeApp(firebaseConfig)
    }

    if (localStateApiToken) {
      commit('SET_API_TOKEN', localStateApiToken)
      dispatch('connect')
    } else {
      const auth = getAuth(firebase)
      onAuthStateChanged(auth, user => {
        if (user) {
          // Firebase user har loggat in
          if (!state.isLoggingIn) {
            commit('SET_IS_LOGGING_IN', true)
            dispatch('firebaseGetToken', user)
          }
        }
      })
    }
  },
  connect ({ commit }) {
    commit('SET_IS_CONNECTING', true)
    API.httpClient.get('user')
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_USER', data.user)
        }
      })
      .catch((error) => {
        console.log('connect error', error)
      })
      .finally(() => {
        commit('SET_IS_CONNECTING', false)
      })
  },
  login ({ commit, dispatch }, loginDetails) {
    commit('SET_IS_LOGGING_IN', true)
    dispatch('firebaseSignIn', loginDetails)
  },
  createNewUser ({ commit, dispatch, rootGetters }, email) {
    return dispatch('country/fetchCountriesOnce', undefined, { root: true })
      .then(() => {
        const country = rootGetters['country/defaultCountry']
        const newUser = {
          email,
          given_name: '',
          surname: '',
          street_name: '',
          street_number: '',
          zip: '',
          city: '',
          country,
          dialling_code_country: country,
          phone_number: ''
        }
        commit('SET_EDITED_USER', newUser)
      })
  },
  firebaseSignIn ({ commit, dispatch }, loginDetails) {
    const auth = getAuth(firebase)
    signInWithEmailAndPassword(auth, loginDetails.email, loginDetails.password)
      .then((firebase) => {
        dispatch('firebaseGetToken', firebase.user)
      })
      .catch((error) => {
        console.log('firebaseSignIn error', error)
        commit('SET_IS_LOGGING_IN', false)
        commit('SET_LOGIN_AUTH', {
          email: loginDetails.email,
          password: loginDetails.password,
          errorCode: error.code
        })
      })
  },
  firebaseGetToken ({ commit, dispatch }, firebaseUser) {
    firebaseUser.getIdToken()
      .then((firebaseToken) => {
        dispatch('getApiToken', firebaseToken)
      })
      .catch((error) => {
        console.log('getIdToken error', error)
        commit('SET_IS_LOGGING_IN', false)
      })
  },
  getApiToken ({ commit }, firebaseToken) {
    API.httpClient.post('user/login', {
      firebase_token: firebaseToken
    })
      .then(({ data }) => {
        if (data.status === 'authenticated') {
          commit('SET_API_TOKEN', data.token)
          commit('SET_USER', data.user)
          commit('SET_SHOW_LOGGED_IN_SNACKBAR', true)
        }
      })
      .catch(error => {
        console.log('getApiToken error', error)
      })
      .finally(() => {
        commit('SET_IS_LOGGING_IN', false)
      })
  },
  logout ({ state, commit }) {
    commit('SET_IS_LOGGING_OUT', true)
    const auth = getAuth(firebase)
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      }).catch((error) => {
        console.log('firebase signout error', error)
        // An error happened.
      })
    return API.httpClient.post('user/logout', {
      token_id: state.apiToken
    })
      .then(({ data }) => {
        if (data.status === 'logged_out') {
          commit('SET_API_TOKEN', null)
          commit('SET_USER', null)
          commit('SET_SHOW_LOGGED_OUT_SNACKBAR', true)
        }
      })
      .catch(error => {
        console.log('logout error', error)
      })
      .finally(() => {
        commit('SET_IS_LOGGING_OUT', false)
      })
  },
  sendOtpEmail ({ commit }, { email }) {
    commit('SET_OTP_LOGIN_EMAIL_FAILED', false)
    commit('SET_OTP_LOGIN_IS_SENDING_EMAIL', true)
    commit('SET_OTP_LOGIN_ERROR_CODE', '')
    return API.httpClient.post('user/request-otp', {
      email
    })
      .then(({ data }) => {
        if (data.status === 'otp_sent') {
          commit('SET_OTP_LOGIN_EMAIL', email)
          commit('SET_OTP_LOGIN_SENT_AT', window.dayjs())
        } else {
          commit('SET_OTP_LOGIN_EMAIL_FAILED', true)
        }
      })
      .catch((error) => {
        console.log('request-otp error', error)
        commit('SET_OTP_LOGIN_EMAIL_FAILED', true)
      })
      .finally(() => {
        commit('SET_OTP_LOGIN_IS_SENDING_EMAIL', false)
      })
  },
  loginWithOtp ({ commit }, { email, otp }) {
    commit('SET_IS_LOGGING_IN', true)
    commit('SET_OTP_LOGIN_ERROR_CODE', '')
    return API.httpClient.post('user/login-with-otp', {
      email,
      otp
    })
      .then(({ data }) => {
        if (data.status === 'authenticated') {
          commit('SET_API_TOKEN', data.token)
          commit('SET_USER', data.user)
          commit('SET_SHOW_LOGGED_IN_SNACKBAR', true)
        } else if (data.status === 'invalid_code') {
          commit('SET_OTP_LOGIN_ERROR_CODE', 'invalid_code')
        } else if (data.status === 'password_expired') {
          commit('SET_OTP_LOGIN_ERROR_CODE', 'password_expired')
        } else if (data.status === 'too_many_attempts') {
          commit('SET_OTP_LOGIN_ERROR_CODE', 'too_many_attempts')
        } else {
          commit('SET_OTP_LOGIN_ERROR_CODE', 'something_went_wrong')
        }
      })
      .catch((error) => {
        console.log('request-otp error', error)
        commit('SET_OTP_LOGIN_ERROR_CODE', 'something_went_wrong')
      })
      .finally(() => {
        commit('SET_IS_LOGGING_IN', false)
      })
  },
  resetPassword ({ commit }, email) {
    commit('SET_PASSWORD_RESET_IS_SENDING_EMAIL', true)
    commit('SET_PASSWORD_RESET_EMAIL_SENT_AT', null)
    commit('SET_PASSWORD_RESET_EMAIL', email)
    const auth = getAuth(firebase)
    sendPasswordResetEmail(auth, email)
      .then(function () {
        // Email sent.
        commit('SET_PASSWORD_RESET_EMAIL_SENT_AT', window.dayjs())
      }).catch(function (error) {
        console.log('resetPassword error', error)
      })
      .finally(() => {
        commit('SET_PASSWORD_RESET_IS_SENDING_EMAIL', false)
      })
  },
  lookupEmail (context, email) {
    return API.httpClient.get('user/lookup', {
      params: {
        email
      }
    })
  },
  searchAddress (context, data) {
    return API.httpClient.get('validate/address/search', {
      params: {
        session: data.session,
        input: data.input,
        country_code: data.country_code
      }
    })
  },
  getPlace (context, data) {
    return API.httpClient.get('validate/address/place', {
      params: {
        session: data.session,
        place_id: data.place_id
      }
    })
  },
  validatePhoneNumber (context, data) {
    return API.httpClient.get('validate/phone', {
      params: {
        dialling_code: data.dialling_code,
        phone_number: data.phone_number
      }
    })
  },
  createUser ({ state, commit }) {
    commit('SET_IS_SAVING_USER', true)
    return API.httpClient.post('user/create-user', {
      email: state.editedUser.email,
      given_name: state.editedUser.given_name,
      surname: state.editedUser.surname,
      dialling_code_country_id: state.editedUser.dialling_code_country.id,
      phone_number: state.editedUser.phone_number,
      street_name: state.editedUser.street_name,
      street_number: state.editedUser.street_number,
      zip: state.editedUser.zip,
      city: state.editedUser.city,
      country_id: state.editedUser.country.id
    })
      .then((result) => {
        commit('SET_USER', result.data.user)
        commit('SET_API_TOKEN', result.data.token)
      })
      .catch((error) => {
        console.log('update user error', error)
      })
      .finally(() => {
        commit('SET_IS_SAVING_USER', false)
      })
  },
  updateUser ({ state, commit }, routeOnSuccess) {
    commit('SET_IS_SAVING_USER', true)
    return API.httpClient.post('user/update', {
      given_name: state.editedUser.given_name,
      surname: state.editedUser.surname,
      dialling_code_country_id: state.editedUser.dialling_code_country.id,
      phone_number: state.editedUser.phone_number,
      street_name: state.editedUser.street_name,
      street_number: state.editedUser.street_number,
      zip: state.editedUser.zip,
      city: state.editedUser.city,
      country_id: state.editedUser.country.id
    })
      .then((result) => {
        commit('SET_USER', result.data.user)
        if (routeOnSuccess) {
          router.push(routeOnSuccess)
        }
      })
      .catch((error) => {
        console.log('update user error', error)
      })
      .finally(() => {
        commit('SET_IS_SAVING_USER', false)
      })
  }
}

export const getters = {
  isLoggedIn (state) {
    return state.user != null
  },
  hasCustomerData (state) {
    return !!(state.user && state.user.given_name && state.user.surname && state.user.street_name && state.user.street_number && state.user.city && state.user.zip && state.user.country)
  }
}
