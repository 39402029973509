import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import sv from 'vuetify/lib/locale/sv'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#003462', /* Båstad Camping färgen */
        secondary: '#BFECFF',
        error: '#FF5252',
        success: '#4CAF50',
        warning: '#FB8C00'
      }
    }
  },
  lang: {
    locales: { sv },
    current: 'sv'
  }
})
