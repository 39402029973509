<template>
  <div class="reservation-dialog">
    <v-dialog v-model="show" persistent max-width="450">
      <v-card v-show="!positionUnavailable" :loading="isLoading">
        <v-card-title class="headline">
          {{ $t('reservationdialog.are_you_still_there') }}
        </v-card-title>

        <v-card-text>
          {{ $t('reservationdialog.reservation_expiring') }}
        </v-card-text>

        <p :class="{ 'text-subtitle-2 text-center': true, 'error--text': remainingSeconds < 60 }">
          {{ remainingTime }}
        </p>
        <v-card-actions class="d-flex flex-column flex-md-row">
          <v-btn @click="discardReservation" color="error" text class="my-2 my-md-0" :block="isMobile">
            {{ $t('reservationdialog.cancel_booking') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn v-if="remainingSeconds > 0" @click="renewReservation" color="success" text :block="isMobile"
            :disabled="isLoading" class="my-2 my-md-0">
            {{ $t('reservationdialog.keep_my_reservation') }}
          </v-btn>
          <v-btn v-else @click="renewReservation" color="success" text :block="isMobile" class="my-2 my-md-0">
            {{ $t('reservationdialog.continue_booking') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-show="positionUnavailable" :loading="isLoading">
        <v-card-title class="headline">
          {{ $t('reservationdialog.occupied') }}
        </v-card-title>

        <v-card-text>
          {{ $t('reservationdialog.booking_expired') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" text :disabled="isLoading" @click="discardReservation">
            {{ $t('reservationdialog.go_back') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="redirectToIndex" persistent width="300">
      <v-card color="error" dark class="pt-4">
        <v-card-text>
          {{ reasonForRedirecting }}
          {{ $t('reservationdialog.redirecting_to_search') }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ReservationDialog',
  components: {},
  props: [],
  data: () => ({
    redirectToIndex: false,
    reasonForRedirecting: ''
  }),
  computed: {
    ...mapState(
      {
        booking: state => state.booking,
        pageName: state => state.currentPageName,
        pagesRequiringReservation: state => state.pagesRequiringReservation,
        pagesRequiringBooking: state => state.pagesRequiringBooking,
        now: state => state.now
      }
    ),
    ...mapGetters(
      {
        hasUnpaidBooking: 'booking/hasUnpaidBooking'
      }
    ),
    show: function () {
      if (this.positionUnavailable) {
        return true
      }
      if (this.hasUnpaidBooking) {
        return false
      }
      return this.remainingSeconds <= 300 || this.positionUnavailable
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown
    },
    positionUnavailable: function () {
      return !this.booking.reservation.is_available && this.booking.reservation.receivedAt != null
    },
    isLoading: function () {
      return this.booking.reservation && this.booking.reservation.request && this.booking.reservation.request.isProcessing
    },
    remainingTime: function () {
      let m = 0; let s = 0
      const rs = this.remainingSeconds
      if (rs < 0) {
        return this.$t('reservationdialog.time_has_passed')
      }
      m = Math.floor(rs / 60)
      s = Math.round(rs - (m * 60))
      if (s <= 9) { s = '0' + s }
      return `${m}:${s}`
    },
    remainingSeconds: function () {
      if (this.booking.reservation && this.booking.reservation.hold && this.booking.reservation.hold.release_time) {
        const s = this.booking.reservation.hold.release_time.diff(this.now, 's')
        return s
      }
      return 1200
    }
  },
  methods: {
    renewReservation: function () {
      this.$store.dispatch('booking/reservePosition')
    },
    discardReservation: function () {
      this.$store.dispatch('booking/discardReservation')
        .then(() => {
          this.$router.push({ name: 'SearchPage' })
        })
    },
    goBackToSearchPage: function () {
      this.$router.push({ name: 'SearchPage' })
    },
    onPageEnter: function () {
      const vm = this
      if (this.pagesRequiringBooking.includes(this.pageName) && (this.booking == null || this.booking.position == null)) {
        this.reasonForRedirecting = this.$t('reservationdialog.no_active_booking')
        this.redirectToIndex = true
        setTimeout(function () {
          this.redirectToIndex = false
          vm.goBackToSearchPage()
        }, 3000)
      } else if (this.pagesRequiringReservation.includes(this.pageName) && (this.booking.reservation == null || this.booking.reservation.hold == null)) {
        this.reasonForRedirecting = this.$t('reservationdialog.no_active_reservation')
        this.redirectToIndex = true
        setTimeout(function () {
          this.redirectToIndex = false
          vm.goBackToSearchPage()
        }, 3000)
      } else {
        this.redirectToIndex = false
      }
    }
  },
  watch: {
    pageName: function () {
      this.onPageEnter()
    },
    'booking.triggerRedirect' () {
      console.log('triggerRedirect')
      this.onPageEnter()
    },
    remainingSeconds: function () {
      if (this.booking.reservation && this.booking.reservation.hold && this.booking.reservation.hold.release_time) {
        const s = this.booking.reservation.hold.release_time.diff(this.now, 's')
        return s
      }
      return 1200
    }
  },
  created () {
    this.onPageEnter()
  }
}
</script>
