<template>
<div class="snackbars-component">
  <v-snackbar
    v-model="showLoggedIn"
    color="success"
  >
    Du är nu inloggad!
    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        @click="showLoggedIn = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    v-model="showLoggedOut"
    color="success"
  >
    Du är nu utloggad!
    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        @click="showLoggedOut = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    v-model="showSaveBookingError"
    color="error"
  >
    {{ saveBookingErrorMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        @click="showSaveBookingError = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    v-model="showSaveCheckinError"
    color="error"
  >
    {{ saveCheckinErrorMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        @click="showSaveCheckinError = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    v-model="showAdultsChangedMessage"
    color="info"
  >
    {{ adultsChangedMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        @click="showAdultsChangedMessage = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SnackbarsComponent',
  components: {

  },
  props: [],
  data: () => ({
    showLoggedIn: false,
    showLoggedOut: false,
    showSaveBookingError: false,
    showSaveCheckinError: false,
    showAdultsChangedMessage: false
  }),
  computed: {
    ...mapState(
      {
        showLoggedInSnackback: state => state.user.showLoggedInSnackback,
        showLoggedOutSnackback: state => state.user.showLoggedOutSnackback,
        saveBookingErrorMessage: state => state.booking.saveBookingErrorMessage,
        saveCheckinErrorMessage: state => state.checkin.saveBookingErrorMessage,
        adultsChangedMessage: state => state.checkin.adultsChangedMessage
      }
    )
  },
  methods: {

  },
  watch: {
    showLoggedInSnackback: function () {
      if (this.showLoggedInSnackback) {
        this.showLoggedIn = true
      }
    },
    showLoggedOutSnackback: function () {
      if (this.showLoggedOutSnackback) {
        this.showLoggedOut = true
      }
    },
    saveBookingErrorMessage: function () {
      if (this.saveBookingErrorMessage != '') {
        this.showSaveBookingError = true
      }
    },
    saveCheckinErrorMessage: function () {
      if (this.saveCheckinErrorMessage != '') {
        this.showSaveCheckinError = true
      }
    },
    adultsChangedMessage: function () {
      if (this.adultsChangedMessage != '') {
        this.showAdultsChangedMessage = true
      }
    },
    showSaveBookingError: function () {
      if (!this.showSaveBookingError) {
        this.$store.commit('booking/SET_SAVE_BOOKING_ERROR_MESSAGE', '')
      }
    },
    showSaveCheckinError: function () {
      if (!this.showSaveCheckinError) {
        this.$store.commit('checkin/SET_SAVE_BOOKING_ERROR_MESSAGE', '')
      }
    },
    showAdultsChangedMessage: function () {
      if (!this.showAdultsChangedMessage) {
        this.$store.commit('checkin/SET_ADULTS_CHANGED_MESSAGE', '')
      }
    }
  },
  created () {

  }
}
</script>
